// akkimwaru-catalog.js
export const akkimwaruCatalog = [
  {
    "publishingDetails": {
      "no": 1,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "ROD",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "2' 19\"",
      "releaseDate": "9/1/23"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6XW2309833",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://www.youtube.com/watch?v=7g7BHY4KTTQ"
  },
  {
    "publishingDetails": {
      "no": 2,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "DALAM HATI",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "1' 36\"",
      "releaseDate": "8/13/23"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6SA2355659",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://www.youtube.com/watch?v=wKrsC3NdjEs"
  },
  {
    "publishingDetails": {
      "no": 3,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "GARING",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "1' 53\"",
      "releaseDate": "3/3/21"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE5752134486",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://www.youtube.com/watch?v=4ZGBv0neKow"
  },
  {
    "publishingDetails": {
      "no": 4,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "MUST MUST",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "1' 46\"",
      "releaseDate": "10/16/21"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6XX2109007",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://www.youtube.com/watch?v=RW2dgoAVITE"
  },
  {
    "publishingDetails": {
      "no": 5,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "ROCKET",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "1' 52\"",
      "releaseDate": "7/11/20"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6132035459",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://www.youtube.com/watch?v=QBebycrKmFI"
  },
  {
    "publishingDetails": {
      "no": 6,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "SIA-SIA OR KENANG",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "1' 58\"",
      "releaseDate": "8/13/23"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6XW2307176",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://www.youtube.com/watch?v=k0RbUMICYXU"
  },
  {
    "publishingDetails": {
      "no": 7,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "TIBA MASA",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "1' 47\"",
      "releaseDate": "8/13/23"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6TI2335047",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://www.youtube.com/watch?v=H40cWBWGO00"
  },
  {
    "publishingDetails": {
      "no": 8,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "VRD",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "2' 56\"",
      "releaseDate": "7/7/23"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6XY2300626",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://www.youtube.com/watch?v=-i0i70IE6OY"
  },
  {
    "publishingDetails": {
      "no": 9,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "3:30",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "2' 20\"",
      "releaseDate": "7/28/22"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://www.youtube.com/watch?v=9FK9MBeCxTw"
  },
  {
    "publishingDetails": {
      "no": 10,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "3:30 pt2",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "2' 44\"",
      "releaseDate": "8/19/22"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://www.youtube.com/watch?v=pHgIkUZe2Yc"
  },
  {
    "publishingDetails": {
      "no": 11,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "AIM MODE",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "1' 57\"",
      "releaseDate": "12/23/22"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://www.youtube.com/watch?v=zeQBM6YoPhw"
  },
  {
    "publishingDetails": {
      "no": 12,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "Aku Select",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "1' 51\"",
      "releaseDate": "9/26/22"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://soundcloud.com/user-342407375/aku-select"
  },
  {
    "publishingDetails": {
      "no": 13,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "APA KHABARR",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "2' 19\"",
      "releaseDate": "9/12/23"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://soundcloud.com/user-342407375/apa-khabarr-1"
  },
  {
    "publishingDetails": {
      "no": 14,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "BUKAN MACAM NI",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "1' 46\"",
      "releaseDate": "1/5/23"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://soundcloud.com/user-342407375/bukan-macam-ni"
  },
  {
    "publishingDetails": {
      "no": 15,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "CHK",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "2'",
      "releaseDate": "11/17/23"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://soundcloud.com/user-342407375/chk"
  },
  {
    "publishingDetails": {
      "no": 16,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "CHK PT 2",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "2' 27\"",
      "releaseDate": "11/20/23"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://soundcloud.com/user-342407375/chk-pt-2"
  },
  {
    "publishingDetails": {
      "no": 17,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "Gettin Better",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "1' 23\"",
      "releaseDate": "10/17/22"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://soundcloud.com/user-342407375/gettin-better"
  },
  {
    "publishingDetails": {
      "no": 18,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "Gimmie Dat",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "1' 44\"",
      "releaseDate": "9/26/22"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://soundcloud.com/user-342407375/gimmie-dat"
  },
  {
    "publishingDetails": {
      "no": 19,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "JANGAN TANYA",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "2' 42\"",
      "releaseDate": "11/12/21"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://soundcloud.com/user-342407375/akkimwaru-jangan-tanya"
  },
  {
    "publishingDetails": {
      "no": 20,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "KAU RUMAH AKU freestyle",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "2' 25\"",
      "releaseDate": "1/21/23"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://soundcloud.com/user-342407375/kau-rumah-aku-freestyle"
  },
  {
    "publishingDetails": {
      "no": 21,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "LEPAK SLATAN",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "1' 49\"",
      "releaseDate": "3/29/22"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://soundcloud.com/user-342407375/lepak-slatan-online-audio"
  },
  {
    "publishingDetails": {
      "no": 22,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "LEPAS PAPER",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "2' 49\"",
      "releaseDate": "11/27/21"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://soundcloud.com/user-342407375/akkimwaru-lepas-paper"
  },
  {
    "publishingDetails": {
      "no": 23,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "MINI WARU",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "2' 09\"",
      "releaseDate": "4/20/23"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://soundcloud.com/user-342407375/mini-waru"
  },
  {
    "publishingDetails": {
      "no": 24,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "Night Ride freestyle",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "1' 29\"",
      "releaseDate": "9/16/22"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://soundcloud.com/user-342407375/night-ride-freestyle"
  },
  {
    "publishingDetails": {
      "no": 25,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "NOT4FREE",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "1' 41\"",
      "releaseDate": "11/8/22"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://soundcloud.com/user-342407375/not4free"
  },
  {
    "publishingDetails": {
      "no": 26,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "OPPS",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "1' 40\"",
      "releaseDate": "9/11/22"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://soundcloud.com/user-342407375/opps"
  },
  {
    "publishingDetails": {
      "no": 27,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "PRADA JEANZ freestyle",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "1' 59\"",
      "releaseDate": "12/8/22"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://soundcloud.com/user-342407375/prada-jeanz"
  },
  {
    "publishingDetails": {
      "no": 28,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "RETAKK SERIBUU",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "1' 49\"",
      "releaseDate": "6/6/23"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://soundcloud.com/user-342407375/retakk-seribuu"
  },
  {
    "publishingDetails": {
      "no": 29,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "She Doubt",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "1' 55\"",
      "releaseDate": "9/27/22"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://soundcloud.com/user-342407375/she-doubt"
  },
  {
    "publishingDetails": {
      "no": 30,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "TUNGGU KAU",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "2' 21\"",
      "releaseDate": "2/18/23"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://soundcloud.com/user-342407375/tunggu-kau"
  },
  {
    "publishingDetails": {
      "no": 31,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "Wake Up",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "1' 46\"",
      "releaseDate": "9/25/22"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://soundcloud.com/user-342407375/wake-up"
  },
  {
    "publishingDetails": {
      "no": 32,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "WARU NAK SPM",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "1' 40\"",
      "releaseDate": "2/20/22"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://soundcloud.com/user-342407375/waru-nak-spm"
  },
  {
    "publishingDetails": {
      "no": 33,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "Weird Ho",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "1' 30\"",
      "releaseDate": "9/28/22"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://soundcloud.com/user-342407375/weird-ho"
  },
  {
    "publishingDetails": {
      "no": 34,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "ZHIPAT",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "2' 26\"",
      "releaseDate": "11/20/22"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": "https://soundcloud.com/user-342407375/zhipat"
  },
  {
    "publishingDetails": {
      "no": 35,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "SEMUA",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "",
      "releaseDate": ""
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ3 24 10012",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 36,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "FAN BIH",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "",
      "releaseDate": ""
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ3 24 10013",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 37,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "BAWA BALIK",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "",
      "releaseDate": ""
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ3 24 10014",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 38,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "SLAMBERR",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "",
      "releaseDate": ""
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ3 24 10015",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 39,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "MONEY RAIN",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "",
      "releaseDate": ""
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ3 24 10016",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 40,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "BZ",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "",
      "releaseDate": ""
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ3 24 10017",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 41,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "3:30 pt.3",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "",
      "releaseDate": ""
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ3 24 10018",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 42,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "NIKE MAN",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "",
      "releaseDate": ""
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ3 24 10019",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 43,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "FLYY",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "",
      "releaseDate": ""
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ3 24 10020",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 44,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "40.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Dyyablo",
          "percentage": "10.00%",
          "publisher": ""
        }
      ]
    },
    "songDetails": {
      "title": "MALAM TU",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "",
      "releaseDate": ""
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ3 24 10021",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 45,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "GARAM GULA",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "",
      "releaseDate": ""
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ3 24 10022",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 46,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "ONLY ME",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "",
      "releaseDate": ""
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ3 24 10023",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 47,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "APA YANG KURANG",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "",
      "releaseDate": ""
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ3 24 10024",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 48,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "HABUK KAT MATA",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "",
      "releaseDate": ""
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ3 24 10025",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 49,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "NLK",
      "artist": [
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "",
      "releaseDate": ""
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ3 24 10026",
      "upc": "",
      "repertoireOwner": "Muhammad Danish Hakimi bin Joni Muhamat"
    },
    "link": ""
  },
// Add more songs for JAYSTATION
];