import React from 'react';
import { useCatalog } from '../../hooks/useCatalog';
import Dashboard from '../Dashboard';
import { ActivityIcon } from 'lucide-react';

const ArtistCatalog = () => {
  const { catalog, isLoading } = useCatalog();

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin mr-2">
          <ActivityIcon className="h-5 w-5 text-primary" />
        </div>
        <span className="text-muted-foreground">Loading...</span>
      </div>
    );
  }

  return <Dashboard catalog={catalog} />;
};

export default ArtistCatalog;