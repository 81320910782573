import React from 'react';
import { ThemeProvider } from "next-themes";
import ArtistCatalog from './components/catalogmanagement/ArtistCatalog';
import { Toaster } from './components/ui/toaster';

function App() {
  return (
    <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
      <div className="min-h-screen bg-background font-sans antialiased">
        <ArtistCatalog />
        <Toaster />
      </div>
    </ThemeProvider>
  );
}

export default App;