import React, { useState, Suspense } from 'react';
import { Card, CardContent } from "./ui/card";
import { ScrollArea } from "./ui/scroll-area";
import { Button } from "./ui/button";
import { useTheme } from "next-themes";
import { ErrorBoundary } from 'react-error-boundary';
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from "../lib/utils";
import MobileNav from './mobile/MobileNav';
import { 
  MoonIcon, 
  SunIcon, 
  ChevronLeftIcon, 
  HomeIcon, 
  CalendarIcon, 
  UsersIcon,
  ChevronRightIcon,
  BarChartIcon,
  FolderIcon,
  ActivityIcon,
  AlertCircleIcon,
  InfoIcon,
  AppWindow,
  SquareArrowOutUpRight
} from "lucide-react";

// Lazy load the tabs for better performance
const ProjectManagementTab = React.lazy(() => import('./projectmanagement/ProjectManagementTab'));
const SummaryTab = React.lazy(() => import('./SummaryTab'));
const ShowsTab = React.lazy(() => import('./ShowsTab'));
const BelieveReportTab = React.lazy(() => import('./BelieveReportTab'));
const BelieveBackstageTab = React.lazy(() => import('./BelieveBackstageTab'));
const ArtistTab = React.lazy(() => import('./catalogmanagement/ArtistTab'));
const InfoModal = React.lazy(() => import('./InfoModal'));

// Loading fallback component
const TabLoadingFallback = () => (
  <div className="flex items-center justify-center p-8">
    <div className="flex items-center gap-2">
      <motion.div
        animate={{ rotate: 360 }}
        transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
      >
        <ActivityIcon className="h-5 w-5 text-primary" />
      </motion.div>
      <span className="text-muted-foreground">Loading...</span>
    </div>
  </div>
);

// Error fallback component
const TabErrorFallback = ({ error, resetErrorBoundary }) => (
  <div className="flex flex-col items-center justify-center p-8 space-y-4">
    <div className="text-destructive">
      <AlertCircleIcon className="h-8 w-8" />
    </div>
    <h3 className="text-lg font-semibold">Something went wrong</h3>
    <p className="text-sm text-muted-foreground">{error.message}</p>
    <Button variant="outline" onClick={resetErrorBoundary}>
      Try again
    </Button>
  </div>
);

const Dashboard = ({ catalog }) => {
  const [selectedArtist, setSelectedArtist] = useState('info');
  const { theme, setTheme } = useTheme();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [artistsExpanded, setArtistsExpanded] = useState(false);
  const [believeExpanded, setBelieveExpanded] = useState(false);

  const renderTabContent = () => {
    return (
      <ErrorBoundary
        FallbackComponent={TabErrorFallback}
        onReset={() => {
          setSelectedArtist('summary');
        }}
      >
        <Suspense fallback={<TabLoadingFallback />}>
          <AnimatePresence mode="wait">
            <motion.div
              key={selectedArtist}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
              className="min-h-full"
            >
              {selectedArtist === 'summary' && <SummaryTab catalog={catalog} />}
              {selectedArtist === 'shows' && <ShowsTab catalog={catalog} />}
              {selectedArtist === 'believe-report' && <BelieveReportTab />}
              {selectedArtist === 'believe-backstage' && <BelieveBackstageTab />}
              {selectedArtist === 'project-management' && <ProjectManagementTab />}
              {selectedArtist === 'info' && <InfoModal />}
              {selectedArtist !== 'summary' && selectedArtist !== 'shows' && 
               selectedArtist !== 'believe-report' && selectedArtist !== 'believe-backstage' && 
               selectedArtist !== 'project-management' && selectedArtist !== 'info' && (
                <ArtistTab
                  artist={selectedArtist}
                  catalog={catalog}
                />
              )}
            </motion.div>
          </AnimatePresence>
        </Suspense>
      </ErrorBoundary>
    );
  };

  return (
    <div className="min-h-screen bg-background flex">
      {/* Desktop Sidebar */}
      <div className={cn(
        "fixed inset-y-0 z-50 flex transition-all duration-300 ease-in-out sidebar",
        sidebarOpen ? "w-[240px] translate-x-0" : "w-16 -translate-x-full md:translate-x-0",
        "hidden md:flex"
      )}>
        <div className="flex h-full w-full flex-col border-r bg-background">
          <div className="sticky top-0 z-40 flex h-16 items-center justify-between border-b bg-background px-4">
            <div className="flex items-center gap-2">
              <img 
                src="https://static.wixstatic.com/media/ebd611_3711b599d89649f093bddf390131d121~mv2.png" 
                alt="Logo" 
                className="h-8 w-auto" 
              />
              {sidebarOpen && <h1 className="text-xl font-bold text-foreground">Dashboard</h1>}
            </div>
            <Button
              variant="ghost"
              size="icon"
              onClick={() => setSidebarOpen(!sidebarOpen)}
              className="ml-auto"
            >
              {sidebarOpen ? <ChevronLeftIcon className="h-4 w-4" /> : <ChevronRightIcon className="h-4 w-4" />}
            </Button>
          </div>

          <ScrollArea className="flex-1">
            <div className="space-y-2 p-2">
              <Button
                variant={selectedArtist === 'summary' ? 'secondary' : 'ghost'}
                className={cn(
                  "w-full justify-start",
                  !sidebarOpen && "justify-center px-2"
                )}
                onClick={() => setSelectedArtist('summary')}
              >
                <HomeIcon className="h-4 w-4" />
                {sidebarOpen && <span className="ml-2">Summary</span>}
              </Button>

              <Button
                variant={selectedArtist === 'shows' ? 'secondary' : 'ghost'}
                className={cn(
                  "w-full justify-start",
                  !sidebarOpen && "justify-center px-2"
                )}
                onClick={() => setSelectedArtist('shows')}
              >
                <CalendarIcon className="h-4 w-4" />
                {sidebarOpen && <span className="ml-2">Shows</span>}
              </Button>

              <div className="space-y-1">
                <Button
                  variant="ghost"
                  className={cn(
                    "w-full justify-start",
                    !sidebarOpen && "justify-center px-2"
                  )}
                  onClick={() => setArtistsExpanded(!artistsExpanded)}
                >
                  <UsersIcon className="h-4 w-4" />
                  {sidebarOpen && (
                    <>
                      <span className="ml-2">Artist Catalog</span>
                      <ChevronRightIcon className={cn(
                        "ml-auto h-4 w-4 transition-transform",
                        artistsExpanded && "rotate-90"
                      )} />
                    </>
                  )}
                </Button>
                
                {artistsExpanded && (
                  <div className={cn(
                    "space-y-1",
                    sidebarOpen ? "ml-4" : "ml-0"
                  )}>
                    {Object.keys(catalog).map((artist) => (
                      <Button
                        key={artist}
                        variant={selectedArtist === artist ? 'secondary' : 'ghost'}
                        className={cn(
                          "w-full justify-start",
                          !sidebarOpen && "justify-center px-2"
                        )}
                        onClick={() => setSelectedArtist(artist)}
                        title={!sidebarOpen ? artist : undefined}
                      >
                        <span className={cn(
                          "h-4 w-4 rounded-full bg-primary/10 flex items-center justify-center text-xs font-medium",
                          selectedArtist === artist && "bg-primary text-primary-foreground"
                        )}>
                          {artist[0].toUpperCase()}
                        </span>
                        {sidebarOpen && <span className="ml-2">{artist}</span>}
                      </Button>
                    ))}
                  </div>
                )}
              </div>

              <div className="space-y-1">
                <Button
                  variant="ghost"
                  className={cn(
                    "w-full justify-start",
                    !sidebarOpen && "justify-center px-2"
                  )}
                  onClick={() => setBelieveExpanded(!believeExpanded)}
                >
                  <AppWindow className="h-4 w-4" />
                  {sidebarOpen && (
                    <>
                      <span className="ml-2">Believe</span>
                      <ChevronRightIcon className={cn(
                        "ml-auto h-4 w-4 transition-transform",
                        believeExpanded && "rotate-90"
                      )} />
                    </>
                  )}
                </Button>
                
                {believeExpanded && (
                  <div className={cn(
                    "space-y-1",
                    sidebarOpen ? "ml-4" : "ml-0"
                  )}>
                    <Button
                      variant={selectedArtist === 'believe-backstage' ? 'secondary' : 'ghost'}
                      className={cn(
                        "w-full justify-start gap-2",
                        !sidebarOpen && "justify-center px-2"
                      )}
                      onClick={() => setSelectedArtist('believe-backstage')}
                    >
                      <SquareArrowOutUpRight className="h-4 w-4" />
                      {sidebarOpen && <span>Backstage</span>}
                    </Button>
                    <Button
                      variant={selectedArtist === 'believe-report' ? 'secondary' : 'ghost'}
                      className={cn(
                        "w-full justify-start gap-2",
                        !sidebarOpen && "justify-center px-2"
                      )}
                      onClick={() => setSelectedArtist('believe-report')}
                    >
                      <BarChartIcon className="h-4 w-4" />
                      {sidebarOpen && <span>Report</span>}
                    </Button>
                  </div>
                )}
              </div>

              <Button
                variant={selectedArtist === 'project-management' ? 'secondary' : 'ghost'}
                className={cn(
                  "w-full justify-start",
                  !sidebarOpen && "justify-center px-2"
                )}
                onClick={() => setSelectedArtist('project-management')}
              >
                <FolderIcon className="h-4 w-4" />
                {sidebarOpen && <span className="ml-2">Project Management</span>}
              </Button>
            </div>
          </ScrollArea>

          <Button
            variant={selectedArtist === 'info' ? 'secondary' : 'ghost'}
            className={cn(
              "w-full",
              !sidebarOpen && "justify-center px-2"
            )}
            onClick={() => setSelectedArtist('info')}
          >
            <InfoIcon className="h-4 w-4" />
            {sidebarOpen && <span className="ml-2">About</span>}
          </Button>

          <div className="sticky bottom-0 border-t bg-background p-4">
            <Button
              variant="ghost"
              size="icon"
              className="w-full"
              onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
            >
              {theme === "dark" ? <SunIcon className="h-4 w-4" /> : <MoonIcon className="h-4 w-4" />}
              {sidebarOpen && <span className="ml-2">{theme === "dark" ? "Light Mode" : "Dark Mode"}</span>}
            </Button>
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className={cn(
        "flex-1 transition-all duration-300 ease-in-out",
        sidebarOpen ? "md:ml-[240px]" : "md:ml-16",
        "ml-0 w-full",
        "pb-16 md:pb-0" // Add padding for mobile bottom nav
      )}>
        <main className="p-4 min-h-screen">
          <Card className="bg-card h-full overflow-hidden">
            <CardContent className="p-0 h-full">
              {/* Mobile Header */}
              <div className="flex items-center justify-between p-4 border-b md:hidden sticky top-0 bg-background/80 backdrop-blur-sm z-10">
                <div className="flex items-center gap-2">
                  <img 
                    src="https://static.wixstatic.com/media/ebd611_3711b599d89649f093bddf390131d121~mv2.png" 
                    alt="Logo" 
                    className="h-8 w-auto" 
                  />
                  <h1 className="text-xl font-bold">SLATAN</h1>
                </div>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
                  className="rounded-full"
                >
                  {theme === "dark" ? 
                    <SunIcon className="h-5 w-5" /> : 
                    <MoonIcon className="h-5 w-5" />
                  }
                </Button>
              </div>

              {/* Content Area */}
              <div className="h-[calc(100vh-8rem)] md:h-[calc(100vh-2rem)] overflow-y-auto">
                <div className="p-4">
                  {renderTabContent()}
                </div>
              </div>
            </CardContent>
          </Card>
        </main>
      </div>

      {/* Mobile Navigation */}
      <MobileNav 
        selectedArtist={selectedArtist}
        setSelectedArtist={setSelectedArtist}
        catalog={catalog}
      />
    </div>
  );
};

export default Dashboard;
