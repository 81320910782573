// offgrid-catalog.js
export const offgridCatalog = [
  {
    "publishingDetails": {
      "roles": [
        {
          "role": "Composer",
          "songwriter": "MUHAMMAD HAKIIM BIN TAHIR ",
          "percentage": "50",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "Author",
          "songwriter": "MUHAMMAD HAKIIM BIN TAHIR ",
          "percentage": "50",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "DND",
      "artist": "Offgrid",
      "language": "Malay",
      "duration": "2'26\"",
      "releaseDate": "2020-08-20"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6A92069070",
      "upc": "",
      "repertoireOwner": "MUHAMMAD HAKIIM BIN TAHIR "
    },
    "link": "https://www.youtube.com/watch?v=Suk1R1_7OJ0"
  },
  {
    "publishingDetails": {
      "roles": [
        {
          "role": "Composer",
          "songwriter": "MUHAMMAD HAKIIM BIN TAHIR ",
          "percentage": "50",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "Author",
          "songwriter": "MUHAMMAD HAKIIM BIN TAHIR ",
          "percentage": "50",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "METER DIGI",
      "artist": "Offgrid",
      "language": "Malay",
      "duration": "2'44\"",
      "releaseDate": "2019-08-19"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "FRX452255628",
      "upc": "",
      "repertoireOwner": "MUHAMMAD HAKIIM BIN TAHIR "
    },
    "link": "https://www.youtube.com/watch?v=17WAl4FjGbE"
  },
  {
    "publishingDetails": {
      "roles": [
        {
          "role": "Composer",
          "songwriter": "JOHNASA",
          "percentage": "25",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "Composer",
          "songwriter": "MUHAMMAD HAKIIM BIN TAHIR ",
          "percentage": "25",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "Author",
          "songwriter": "MUHAMMAD HAKIIM BIN TAHIR ",
          "percentage": "3",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "SEBELUM SLATAN",
      "artist": "Offgrid",
      "language": "Malay",
      "duration": "2'44\"",
      "releaseDate": "2020-12-30"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6QE2021965",
      "upc": "",
      "repertoireOwner": "MUHAMMAD HAKIIM BIN TAHIR "
    },
    "link": "https://www.youtube.com/watch?v=17WAl4FjGbE"
  },
  {
    "publishingDetails": {
      "roles": [
        {
          "role": "Composer",
          "songwriter": "MUHAMMAD HAKIIM BIN TAHIR ",
          "percentage": "50",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "Author",
          "songwriter": "MUHAMMAD HAKIIM BIN TAHIR ",
          "percentage": "50",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "SELALU ADA",
      "artist": "Offgrid",
      "language": "Malay",
      "duration": "2'44\"",
      "releaseDate": "2019-09-27"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6I31934262",
      "upc": "",
      "repertoireOwner": "MUHAMMAD HAKIIM BIN TAHIR "
    },
    "link": "https://www.youtube.com/watch?v=nYWEraCh9jc"
  },
  {
    "publishingDetails": {
      "roles": [
        {
          "role": "Composer",
          "songwriter": "JOHNASA",
          "percentage": "25",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "Composer",
          "songwriter": "MUHAMMAD HAKIIM BIN TAHIR ",
          "percentage": "25",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "Author",
          "songwriter": "MUHAMMAD HAKIIM BIN TAHIR ",
          "percentage": "25",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "Author",
          "songwriter": "NOBI",
          "percentage": "25",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "SEPI LEPAS SAYU",
      "artist": "Offgrid",
      "language": "Malay",
      "duration": "2'35\"",
      "releaseDate": "2020-04-18"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "QZDA82052787",
      "upc": "",
      "repertoireOwner": "MUHAMMAD HAKIIM BIN TAHIR "
    },
    "link": "https://www.youtube.com/watch?v=M5XOVaaShxM"
  },
  {
    "publishingDetails": {
      "roles": [
        {
          "role": "Composer",
          "songwriter": "JOHNASA",
          "percentage": "25",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "Composer",
          "songwriter": "MUHAMMAD HAKIIM BIN TAHIR ",
          "percentage": "25",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "Author",
          "songwriter": "MUHAMMAD HAKIIM BIN TAHIR ",
          "percentage": "25",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "Author",
          "songwriter": "NOBI",
          "percentage": "25",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "SHE LIKE",
      "artist": "Offgrid",
      "language": "Malay",
      "duration": "3'47\"",
      "releaseDate": "2020-01-02"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE5751901691",
      "upc": "",
      "repertoireOwner": "MUHAMMAD HAKIIM BIN TAHIR "
    },
    "link": "https://www.youtube.com/watch?v=yr178Un3GoY"
  },
  {
    "publishingDetails": {
      "roles": [
        {
          "role": "Composer",
          "songwriter": "AXEL",
          "percentage": "12.5",
          "publisher": ""
        },
        {
          "role": "Composer",
          "songwriter": "MUHAMMAD HAKIIM BIN TAHIR ",
          "percentage": "25",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "Componser",
          "songwriter": "WUZGUT",
          "percentage": "12.5",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "Author",
          "songwriter": "MUHAMMAD HAKIIM BIN TAHIR ",
          "percentage": "40",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "Author",
          "songwriter": "WUZGUT",
          "percentage": "10",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "TIANG",
      "artist": "Offgrid",
      "language": "Malay",
      "duration": "3'12\"",
      "releaseDate": "2023-05-31"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6QE2360523",
      "upc": "",
      "repertoireOwner": "MUHAMMAD HAKIIM BIN TAHIR "
    },
    "link": "https://www.youtube.com/watch?v=gjg-Hwrok3k"
  },
  {
    "publishingDetails": {
      "roles": [
        {
          "role": "Composer",
          "songwriter": "MUHAMMAD HAKIIM BIN TAHIR",
          "percentage": "50",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "Author",
          "songwriter": "MUHAMMAD HAKIIM BIN TAHIR",
          "percentage": "50",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "VOICEMAIL",
      "artist": "Offgrid",
      "language": "Malay",
      "duration": "3'08\"",
      "releaseDate": "2020-02-21"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "QZFZ42072655",
      "upc": "",
      "repertoireOwner": "MUHAMMAD HAKIIM BIN TAHIR"
    },
    "link": "https://www.youtube.com/watch?v=nE0j84_LK9w"
  },
];