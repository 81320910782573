// quai-catalog.js
export const quaiCatalog = [
  {
    "publishingDetails": {
      "no": 1,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "R Sterling",
      "artist": [
        "Quai"
      ],
      "language": "Malay",
      "duration": "1' 31\"",
      "releaseDate": "2023-07-28"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6XW2387784",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://music.apple.com/my/album/r-sterling/1697860983?i=1697860989"
  },
  {
    "publishingDetails": {
      "no": 2,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "ROTI N KEJU",
      "artist": [
        "Quai"
      ],
      "language": "Malay",
      "duration": "2' 38\"",
      "releaseDate": "2023-07-28"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6SA2307542",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://music.apple.com/my/album/roti-n-keju/1697860983?i=1697861422"
  },
  {
    "publishingDetails": {
      "no": 3,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "TAK SURE",
      "artist": [
        "Quai"
      ],
      "language": "Malay",
      "duration": "2' 16\"",
      "releaseDate": "2023-07-28"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6XW2325278",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://music.apple.com/my/album/tak-sure/1697860983?i=1697861546"
  },
  {
    "publishingDetails": {
      "no": 4,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "TAJ MAHAL",
      "artist": [
        "Quai"
      ],
      "language": "Malay",
      "duration": "2' 08\"",
      "releaseDate": "2023-07-28"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6TI2311305",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://music.apple.com/my/album/taj-mahal/1697860983?i=1697861550"
  },
  {
    "publishingDetails": {
      "no": 5,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "SHILKY",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "MR NOBODY",
      "artist": [
        "Quai",
        "Shilky"
      ],
      "language": "Malay",
      "duration": "2' 39\"",
      "releaseDate": "2023-07-28"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6XW2346610",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://music.apple.com/my/album/mr-nobody-feat-shilky/1697860983?i=1697861807"
  },
  {
    "publishingDetails": {
      "no": 6,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "KELUAR DARI BLOK",
      "artist": [
        "Quai"
      ],
      "language": "Malay",
      "duration": "2' 26\"",
      "releaseDate": "2023-07-28"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6TI2398199",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://music.apple.com/my/album/keluar-dari-blok/1697860983?i=1697861827"
  },
  {
    "publishingDetails": {
      "no": 7,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "ALPHA",
      "artist": [
        "Quai"
      ],
      "language": "Malay",
      "duration": "2' 05\"",
      "releaseDate": "2023-07-28"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6TI2381037",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://music.apple.com/my/album/alpha/1697860983?i=1697861833"
  },
  {
    "publishingDetails": {
      "no": 8,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "MOHAMMAD AIMAN BIN AMINUDDIN",
          "percentage": "25.00%",
          "publisher": ""
        }
      ]
    },
    "songDetails": {
      "title": "OT",
      "artist": [
        "Quai",
        "Ichu"
      ],
      "language": "Malay",
      "duration": "2' 34\"",
      "releaseDate": "2023-07-28"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6TI2339407",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://music.apple.com/my/album/ot-feat-ichu/1697860983?i=1697862076"
  },
  {
    "publishingDetails": {
      "no": 9,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "4U",
      "artist": [
        "Quai"
      ],
      "language": "Malay",
      "duration": "2' 21\"",
      "releaseDate": "2023-07-28"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6XW2376530",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://music.apple.com/my/album/4u/1697860983?i=1697862084"
  },
  {
    "publishingDetails": {
      "no": 10,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "NOBI",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "SERASI",
      "artist": [
        "Quai",
        "Nobi"
      ],
      "language": "Malay",
      "duration": "2' 22\"",
      "releaseDate": "2023-07-28"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6TI2381482",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://music.apple.com/my/album/serasi-feat-nobi/1697860983?i=1697862088"
  },
  {
    "publishingDetails": {
      "no": 11,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Izhar bin Ishak",
          "percentage": "25.00%",
          "publisher": "TAJA ARCHIVE"
        }
      ]
    },
    "songDetails": {
      "title": "RASA",
      "artist": [
        "Quai",
        "Izhar"
      ],
      "language": "Malay",
      "duration": "3' 12\"",
      "releaseDate": "2023-07-28"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6QE2372755\n",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://music.apple.com/my/album/rasa-feat-izhar/1697860983?i=1697862387"
  },
  {
    "publishingDetails": {
      "no": 12,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Izhar bin Ishak",
          "percentage": "25.00%",
          "publisher": "TAJA ARCHIVE"
        }
      ]
    },
    "songDetails": {
      "title": "PUNCAK",
      "artist": [
        "Quai",
        "Izhar"
      ],
      "language": "Malay",
      "duration": "2' 57\"",
      "releaseDate": "2023-07-28"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6TI2389460",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://music.apple.com/my/album/puncak-feat-izhar/1697860983?i=1697862395"
  },
  {
    "publishingDetails": {
      "no": 13,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "GARD",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "PEKAN KE KOTA",
      "artist": [
        "Quai",
        "Gard"
      ],
      "language": "Malay",
      "duration": "3' 45\"",
      "releaseDate": "2023-07-28"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6XY2316222",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://music.apple.com/my/album/pekan-ke-kota/1697860983?i=1697862399"
  },
  {
    "publishingDetails": {
      "no": 14,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "20.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Izhar bin Ishak",
          "percentage": "20.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "A",
          "songwriter": "NIK MUHAMMAD HAZIQ BIN AJ'MAN",
          "percentage": "10.00%",
          "publisher": ""
        }
      ]
    },
    "songDetails": {
      "title": "NO DELAY",
      "artist": [
        "Quai",
        "Izhar",
        "Nic"
      ],
      "language": "Malay",
      "duration": "3' 16\"",
      "releaseDate": "2023-07-28"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6XW2382721",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://music.apple.com/my/album/no-delay-feat-izhar-nic/1697860983?i=1697862568"
  },
  {
    "publishingDetails": {
      "no": 15,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "LASTKHALIF",
          "percentage": "25.00%",
          "publisher": ""
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "SYUKUR",
      "artist": [
        "Quai",
        "LastKhalif"
      ],
      "language": "Malay",
      "duration": "1' 56\"",
      "releaseDate": "2023-07-28"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6SA2390506",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://music.apple.com/my/album/syukur-feat-lastkhalif/1697860983?i=1697862574"
  },
  {
    "publishingDetails": {
      "no": 16,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "JAYSTATION",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "Budak Pulau",
      "artist": [
        "Quai",
        "JAYSTATION"
      ],
      "language": "Malay/English",
      "duration": "3' 06\"",
      "releaseDate": "2023-07-28"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6XW2355757",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://music.apple.com/my/album/budak-pulau/1697860983?i=1697862798"
  },
  {
    "publishingDetails": {
      "no": 18,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "30.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "KLOUDS",
          "percentage": "20.00%",
          "publisher": ""
        }
      ]
    },
    "songDetails": {
      "title": "APA KAU TAHU",
      "artist": [
        "Quai",
        "KLOUDS"
      ],
      "language": "Malay",
      "duration": "2'54\"",
      "releaseDate": "2019-10-18"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "QZES72050847",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://youtu.be/2KUr1Lp5N4o?si=rMIpuB5do3eo2pYA"
  },
  {
    "publishingDetails": {
      "no": 19,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "ANTI",
      "artist": [
        "Quai"
      ],
      "language": "Malay",
      "duration": "3'3\"",
      "releaseDate": "2016-09-17"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=RPr1MKRuEJ0"
  },
  {
    "publishingDetails": {
      "no": 20,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "ART BRATZ",
      "artist": [
        "Quai"
      ],
      "language": "Malay",
      "duration": "2'0\"",
      "releaseDate": "2020-07-27"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6I32050707",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=DnqA4kRCvTU"
  },
  {
    "publishingDetails": {
      "no": 21,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "32.50%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "JOHNASA",
          "percentage": "12.50%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "32.50%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "JOHNASA",
          "percentage": "12.50%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "BAEBLADE",
      "artist": [
        "Quai"
      ],
      "language": "Malay",
      "duration": "3'55\"",
      "releaseDate": "2020-07-27"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6I32050708",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=EM_7pkjY32s"
  },
  {
    "publishingDetails": {
      "no": 22,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "BROKEBOIS",
      "artist": [
        "Quai"
      ],
      "language": "Malay",
      "duration": "3'4\"",
      "releaseDate": "2017-08-26"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "QZES72050846",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=N5O4FdliOx4"
  },
  {
    "publishingDetails": {
      "no": 23,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "35.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Guccimith",
          "percentage": "15.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "35.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Guccimith",
          "percentage": "15.00%",
          "publisher": ""
        }
      ]
    },
    "songDetails": {
      "title": "FAHAM",
      "artist": [
        "Quai",
        "Guccimith"
      ],
      "language": "Malay",
      "duration": "2'54\"",
      "releaseDate": "2020-07-27"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6I32050709",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=cs4cYzhMI3o"
  },
  {
    "publishingDetails": {
      "no": 24,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "37.50%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Arief Othman",
          "percentage": "12.50%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "37.50%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Arief Othman",
          "percentage": "12.50%",
          "publisher": ""
        }
      ]
    },
    "songDetails": {
      "title": "FANTA ANGGUR",
      "artist": [
        "Quai",
        "Orang Malaya"
      ],
      "language": "Malay",
      "duration": "2'20\"",
      "releaseDate": "2020-05-24"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6I32004981",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=oGHUCILb6d0"
  },
  {
    "publishingDetails": {
      "no": 25,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "40.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Shazrul Rejab",
          "percentage": "5.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Hakiim Tahir",
          "percentage": "5.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "40.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Shazrul Rejab",
          "percentage": "5.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Hakiim Tahir",
          "percentage": "5.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "FEEL LIKE KMAIN",
      "artist": [
        "Quai",
        "Gard",
        "Offgrid"
      ],
      "language": "Malay",
      "duration": "3'51\"",
      "releaseDate": "2020-07-27"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6I32050710",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=U_72hewAXKo"
  },
  {
    "publishingDetails": {
      "no": 26,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "G E L 4 P",
      "artist": [
        "Quai"
      ],
      "language": "Malay",
      "duration": "1'35\"",
      "releaseDate": "2019-07-18"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE5751988976",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=OVqbHC_m3u8"
  },
  {
    "publishingDetails": {
      "no": 27,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "42.5",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Hakiim Tahir",
          "percentage": "7.50%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "42.5",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Hakiim Tahir",
          "percentage": "7.50%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "JANJI",
      "artist": [
        "Quai",
        "Offgrid"
      ],
      "language": "Malay",
      "duration": "2'57\"",
      "releaseDate": "2020-07-27"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6I32050711",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=Ua7GA0DZswk"
  },
  {
    "publishingDetails": {
      "no": 28,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "K 3 C 4 H",
      "artist": [
        "Quai"
      ],
      "language": "Malay",
      "duration": "2'5\"",
      "releaseDate": "2019-07-18"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6HN1977513",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://open.spotify.com/track/27lbY9ZKXBMGStFS5rIwXQ?si=d78908de19674d13"
  },
  {
    "publishingDetails": {
      "no": 29,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "KAWAN LAWAN",
      "artist": [
        "Quai"
      ],
      "language": "Malay",
      "duration": "3'52\"",
      "releaseDate": "2016-12-07"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=eE3mROkbiHQ"
  },
  {
    "publishingDetails": {
      "no": 30,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "N O C L U E",
      "artist": [
        "Quai"
      ],
      "language": "Malay",
      "duration": "2'27\"",
      "releaseDate": "2019-03-05"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE5751988980",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=ODr-PJFSiCM"
  },
  {
    "publishingDetails": {
      "no": 31,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "OPP",
      "artist": [
        "Quai"
      ],
      "language": "Malay",
      "duration": "2'19\"",
      "releaseDate": "2021-12-01"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6XY2146881",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=yN9BSD57WXA"
  },
  {
    "publishingDetails": {
      "no": 32,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "R I N D U 4 A M",
      "artist": [
        "Quai"
      ],
      "language": "Malay",
      "duration": "2'31\"",
      "releaseDate": "2019-07-18"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE5751988978",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=j7Gjhz9D0jI"
  },
  {
    "publishingDetails": {
      "no": 33,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "RUPA LUPA",
      "artist": [
        "Quai"
      ],
      "language": "Malay",
      "duration": "3'23\"",
      "releaseDate": "2016-10-17"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=yXRs4Sf48qk"
  },
  {
    "publishingDetails": {
      "no": 34,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "S 0 B 3 R",
      "artist": [
        "Quai"
      ],
      "language": "Malay",
      "duration": "2'7\"",
      "releaseDate": "2019-03-30"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6HN1977512",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=kupTPNM6pc0"
  },
  {
    "publishingDetails": {
      "no": 35,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "SIAPA TAU",
      "artist": [
        "Quai"
      ],
      "language": "Malay",
      "duration": "3'20\"",
      "releaseDate": "2020-05-31"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6I32023108",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=2uoB01-ktyc"
  },
  {
    "publishingDetails": {
      "no": 36,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "40.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Shazrul Rejab",
          "percentage": "5.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Hakiim Tahir",
          "percentage": "5.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "40.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Shazrul Rejab",
          "percentage": "5.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Hakiim Tahir",
          "percentage": "5.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "SKYWALKER",
      "artist": [
        "Quai",
        "Gard",
        "Offgrid"
      ],
      "language": "Malay",
      "duration": "3'15\"",
      "releaseDate": "2020-07-27"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6I32050713",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=YLcYlxxHKeI"
  },
  {
    "publishingDetails": {
      "no": 37,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "SNAKES",
      "artist": [
        "Quai"
      ],
      "language": "Malay",
      "duration": "2'40\"",
      "releaseDate": "2020-11-11"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6SA2055944",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=jv3PmUUAKqo"
  },
  {
    "publishingDetails": {
      "no": 36,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "30.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Shazrul Rejab",
          "percentage": "6.25%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "SXPH",
          "percentage": "3.75%",
          "publisher": "FAITHFUL MUSIC SDN BHD"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "30.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Shazrul Rejab",
          "percentage": "6.25%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "SXPH",
          "percentage": "3.75%",
          "publisher": "FAITHFUL MUSIC SDN BHD"
        }
      ]
    },
    "songDetails": {
      "title": "SWIPE UP",
      "artist": [
        "Quai",
        "Gard",
        "SXPH"
      ],
      "language": "Malay",
      "duration": "3'2\"",
      "releaseDate": "2020-07-27"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6I32050714",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=s3AUOOahIzY"
  },
  {
    "publishingDetails": {
      "no": 39,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "T E N 4 N G",
      "artist": [
        "Quai"
      ],
      "language": "Malay",
      "duration": "2'27\"",
      "releaseDate": "2019-07-18"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE5751988975",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=aGE2vAplGNU"
  },
  {
    "publishingDetails": {
      "no": 40,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "T R I P S E N D U",
      "artist": [
        "Quai"
      ],
      "language": "Malay",
      "duration": "2'37\"",
      "releaseDate": "2019-05-20"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE5751988977",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=zbTEeqnPCrQ"
  },
  {
    "publishingDetails": {
      "no": 41,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "33.34%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Hakiim Tahir",
          "percentage": "4.17%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Shazrul Rejab",
          "percentage": "4.17%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "33.34%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Shazrul Rejab",
          "percentage": "4.17%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Hakiim Tahir",
          "percentage": "4.17%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "TANYA TUHAN",
      "artist": [
        "Quai",
        "Gard",
        "Offgrid"
      ],
      "language": "Malay",
      "duration": "4'25\"",
      "releaseDate": "2020-07-27"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6I32050715",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=pvxqtY-z2Hw"
  },
  {
    "publishingDetails": {
      "no": 41,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "TOP BWOI",
      "artist": [
        "Quai"
      ],
      "language": "Malay",
      "duration": "1'48\"",
      "releaseDate": "2020-05-24"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6I32004979",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=a8B3pt87F7s"
  },
  {
    "publishingDetails": {
      "no": 41,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "TTG",
      "artist": [
        "Quai"
      ],
      "language": "Malay",
      "duration": "2'16\"",
      "releaseDate": "2019-07-29"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=u7IMJSxHmKY"
  },
  {
    "publishingDetails": {
      "no": 42,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Quai",
          "percentage": "10.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Izhar",
          "percentage": "40.00%",
          "publisher": "TAJA ARCHIVE SDN BHD"
        }
      ]
    },
    "songDetails": {
      "title": "X TRUST",
      "artist": [
        "Quai",
        "Izhar"
      ],
      "language": "Malay",
      "duration": "3'13\"",
      "releaseDate": "2020-05-31"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "QZES72050851",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=Ttc2Qt_GKjs"
  },
  {
    "publishingDetails": {
      "no": 43,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Quai",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Izhar",
          "percentage": "25.00%",
          "publisher": "TAJA ARCHIVE SDN BHD"
        }
      ]
    },
    "songDetails": {
      "title": "YUNG MALAY",
      "artist": [
        "Quai",
        "Izhar"
      ],
      "language": "Malay",
      "duration": "2'28\"",
      "releaseDate": "2020-05-24"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6I32004983",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=4LK5u61wHxk"
  },
  {
    "publishingDetails": {
      "no": 44,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Quai",
          "percentage": "30.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "DEUSTIGARATUS",
          "percentage": "25.00%",
          "publisher": ""
        }
      ]
    },
    "songDetails": {
      "title": "ZEN",
      "artist": [
        "Quai"
      ],
      "language": "Malay",
      "duration": "3'13\"",
      "releaseDate": "2018-06-13"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE5BU1859026",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=u_mszTKXAo0"
  },
  {
    "publishingDetails": {
      "no": 45,
      "roles": [
        {
          "role": "C",
          "songwriter": "QUAI",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Quai",
          "percentage": "30.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "DEUSTIGARATUS",
          "percentage": "25.00%",
          "publisher": ""
        }
      ]
    },
    "songDetails": {
      "title": "STAR POSE",
      "artist": [
        "Quai"
      ],
      "language": "Malay",
      "duration": "1'41\"",
      "releaseDate": "2020-05-24"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6I32004978",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=xRt0smLqWtw"
  },
  {
    "publishingDetails": {
      "roles": [
        {
          "role": "Author",
          "songwriter": "Ikhmal Danial Bin Helme Iskandar",
          "percentage": "50",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "Composer",
          "songwriter": "LastKhalif",
          "percentage": "50",
          "publisher": ""
        }
      ]
    },
    "songDetails": {
      "title": "TONY",
      "artist": "Quai",
      "language": "Malay",
      "duration": "2'13\"",
      "releaseDate": "2024-11-11"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "SE6SA2455594",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=S_eChBYHkLc"
  },
  {
    "publishingDetails": {
      "roles": [
        {
          "role": "Author",
          "songwriter": "Ikhmal Danial Bin Helme Iskandar",
          "percentage": "15",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "Author",
          "songwriter": "Arief Othman",
          "percentage": "15",
          "publisher": ""
        },
        {
          "role": "Author",
          "songwriter": "Yuna",
          "percentage": "20",
          "publisher": "YUNA ROOM RECORDS"
        },
        {
          "role": "Composer",
          "songwriter": "Ikhmal Danial Bin Helme Iskandar",
          "percentage": "0",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "SABAR SAYANG",
      "artist": "Quai",
      "language": "Malay",
      "duration": "3'59\"",
      "releaseDate": "2024-11-21"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "MYSZ32410027",
      "upc": "",
      "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
    },
    "link": "https://www.youtube.com/watch?v=bPviQDN0VD0"
  }
      // Add more songs for Quai
  ];