// nobi-catalog.js
export const nobiCatalog = [
  {
    "publishingDetails": {
      "no": 1,
      "roles": [
        {
          "role": "C",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "Benci-Ta",
      "artist": [
        "Nobi"
      ],
      "language": "Malay",
      "duration": "2' 5\"",
      "releaseDate": "1/9/21"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "QZHNA2090978",
      "upc": "",
      "repertoireOwner": "Amirul Asrar bin Mazlan "
    },
    "link": "https://www.youtube.com/watch?v=AKpIyu4zouw"
  },
  {
    "publishingDetails": {
      "no": 2,
      "roles": [
        {
          "role": "C",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Johnasa",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "TAK KENAL",
      "artist": [
        "Nobi"
      ],
      "language": "Malay",
      "duration": "2' 46\"",
      "releaseDate": "6/18/21"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6QE2157458",
      "upc": "",
      "repertoireOwner": "Amirul Asrar bin Mazlan "
    },
    "link": "https://www.youtube.com/watch?v=mfjnOeqkqaQ"
  },
  {
    "publishingDetails": {
      "no": 3,
      "roles": [
        {
          "role": "C",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Johnasa",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "Ballerina",
      "artist": [
        "Nobi",
        "Johnasa"
      ],
      "language": "Malay",
      "duration": "1' 47\"",
      "releaseDate": "12/25/20"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "QZFYZ2074691",
      "upc": "",
      "repertoireOwner": "Amirul Asrar bin Mazlan "
    },
    "link": "https://www.youtube.com/watch?v=zZgT4KssTbo"
  },
  {
    "publishingDetails": {
      "no": 4,
      "roles": [
        {
          "role": "C",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "Bazir Waktu",
      "artist": [
        "Nobi"
      ],
      "language": "Malay",
      "duration": "3' 24\"",
      "releaseDate": "5/1/21"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "QZFZ52046041",
      "upc": "",
      "repertoireOwner": "Amirul Asrar bin Mazlan "
    },
    "link": "https://www.youtube.com/watch?v=3WPC-BWFau8"
  },
  {
    "publishingDetails": {
      "no": 5,
      "roles": [
        {
          "role": "C",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Johnasa",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "Tak Ki$ah",
      "artist": [
        "Nobi",
        "Johnasa"
      ],
      "language": "Malay",
      "duration": "2' 24\"",
      "releaseDate": "4/4/23"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "QZK6P1947267",
      "upc": "",
      "repertoireOwner": "Amirul Asrar bin Mazlan "
    },
    "link": "https://www.youtube.com/watch?v=8TcIWEySoqo"
  },
  {
    "publishingDetails": {
      "no": 6,
      "roles": [
        {
          "role": "C",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "Impian",
      "artist": [
        "Nobi"
      ],
      "language": "Malay",
      "duration": "2' 59\"",
      "releaseDate": "1/1/21"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "QZK6Q1980539",
      "upc": "",
      "repertoireOwner": "Amirul Asrar bin Mazlan "
    },
    "link": "https://www.youtube.com/watch?v=jK6Zfxd95pc"
  },
  {
    "publishingDetails": {
      "no": 7,
      "roles": [
        {
          "role": "C",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "Sinaran",
      "artist": [
        "Nobi"
      ],
      "language": "Malay",
      "duration": "3' 39\"",
      "releaseDate": "4/20/21"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "QZK6J2037344",
      "upc": "",
      "repertoireOwner": "Amirul Asrar bin Mazlan "
    },
    "link": "https://www.youtube.com/watch?v=lwso82oJHls"
  },
  {
    "publishingDetails": {
      "no": 8,
      "roles": [
        {
          "role": "C",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Johnasa",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "DÉJÀ VU",
      "artist": [
        "Nobi"
      ],
      "language": "Malay",
      "duration": "3' 02\"",
      "releaseDate": "6/25/21"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6QE2169084",
      "upc": "",
      "repertoireOwner": "Amirul Asrar bin Mazlan "
    },
    "link": "https://www.youtube.com/watch?v=vQ0-H44igis"
  },
  {
    "publishingDetails": {
      "no": 9,
      "roles": [
        {
          "role": "C",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "RASA",
      "artist": [
        "Nobi"
      ],
      "language": "Malay",
      "duration": "3' 28\"",
      "releaseDate": "8/13/21"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6TI2122089",
      "upc": "",
      "repertoireOwner": "Amirul Asrar bin Mazlan "
    },
    "link": "https://www.youtube.com/watch?v=T6ihKLjqVPs"
  },
  {
    "publishingDetails": {
      "no": 10,
      "roles": [
        {
          "role": "C",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "DEMIYU",
      "artist": [
        "Nobi"
      ],
      "language": "Malay",
      "duration": "3' 39\"",
      "releaseDate": "8/25/21"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6TI2180246",
      "upc": "",
      "repertoireOwner": "Amirul Asrar bin Mazlan "
    },
    "link": "https://www.youtube.com/watch?v=zmmiCJXgrqw"
  },
  {
    "publishingDetails": {
      "no": 11,
      "roles": [
        {
          "role": "C",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "PINTU",
      "artist": [
        "Nobi"
      ],
      "language": "Malay",
      "duration": "2' 59\"",
      "releaseDate": "11/17/21"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6XY2142522",
      "upc": "",
      "repertoireOwner": "Amirul Asrar bin Mazlan "
    },
    "link": "https://www.youtube.com/watch?v=YhF_wU4D4Aw"
  },
  {
    "publishingDetails": {
      "no": 12,
      "roles": [
        {
          "role": "C",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Johnasa",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "PELUKAN TENANG",
      "artist": [
        "Nobi"
      ],
      "language": "Malay",
      "duration": "3' 25\"",
      "releaseDate": "5/8/22"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6I32263788",
      "upc": "",
      "repertoireOwner": "Amirul Asrar bin Mazlan "
    },
    "link": "https://www.youtube.com/watch?v=U4zTN1T0b4Q"
  },
  {
    "publishingDetails": {
      "no": 13,
      "roles": [
        {
          "role": "C",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Johnasa",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "KATSANE",
      "artist": [
        "Nobi",
        "Johnasa"
      ],
      "language": "Malay",
      "duration": "3' 24\"",
      "releaseDate": "27/11/2022"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE66N2235981",
      "upc": "",
      "repertoireOwner": "Amirul Asrar bin Mazlan "
    },
    "link": "https://www.youtube.com/watch?v=tstFx0fkUu4"
  },
  {
    "publishingDetails": {
      "no": 14,
      "roles": [
        {
          "role": "C",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Johnasa",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "SORRY",
      "artist": [
        "Nobi",
        "Johnasa"
      ],
      "language": "Malay",
      "duration": "2' 59\"",
      "releaseDate": "11/30/22"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE66N2247951",
      "upc": "",
      "repertoireOwner": "Amirul Asrar bin Mazlan "
    },
    "link": "https://www.youtube.com/watch?v=idmyzrgqRhM"
  },
  {
    "publishingDetails": {
      "no": 15,
      "roles": [
        {
          "role": "C",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "Pilihan",
      "artist": [
        "Nobi"
      ],
      "language": "Malay",
      "duration": "2' 39\"",
      "releaseDate": "4/20/21"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "QZK6J2037345",
      "upc": "",
      "repertoireOwner": "Amirul Asrar bin Mazlan "
    },
    "link": "https://www.youtube.com/watch?v=66btPW7luDo"
  },
  {
    "publishingDetails": {
      "no": 16,
      "roles": [
        {
          "role": "C",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Johnasa",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "HARUSKAH AKU TERIMANYA",
      "artist": [
        "Nobi",
        "Johnasa"
      ],
      "language": "Malay",
      "duration": "3' 06\"",
      "releaseDate": "3/27/23"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6HN2357429",
      "upc": "",
      "repertoireOwner": "Amirul Asrar bin Mazlan "
    },
    "link": "https://www.youtube.com/watch?v=OGwHk3vVXlA"
  },
  {
    "publishingDetails": {
      "no": 17,
      "roles": [
        {
          "role": "C",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Johnasa",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "DUDUK",
      "artist": [
        "Nobi",
        "Johnasa"
      ],
      "language": "Malay",
      "duration": "3' 01\"",
      "releaseDate": "6/14/23"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6XY2300293",
      "upc": "",
      "repertoireOwner": "Amirul Asrar bin Mazlan "
    },
    "link": "https://www.youtube.com/watch?v=E2xDDi1LIUY"
  },
  {
    "publishingDetails": {
      "no": 18,
      "roles": [
        {
          "role": "C",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Johnasa",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "MATAHARI",
      "artist": [
        "Nobi",
        "Johnasa"
      ],
      "language": "Malay",
      "duration": "3' 31\"",
      "releaseDate": "11/15/23"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ32310001",
      "upc": "",
      "repertoireOwner": "Amirul Asrar bin Mazlan "
    },
    "link": "https://www.youtube.com/watch?v=8F2J7vH6f-I"
  },
  {
    "publishingDetails": {
      "no": 19,
      "roles": [
        {
          "role": "C",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Johnasa",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "BINTANG",
      "artist": [
        "Nobi",
        "Johnasa"
      ],
      "language": "Malay",
      "duration": "3' 12\"",
      "releaseDate": "3/16/23"
    },
    "masterRecordingDetails": {
      "version": "",
      "isrc": "SE6HN2324002",
      "upc": "",
      "repertoireOwner": "Amirul Asrar bin Mazlan "
    },
    "link": "https://www.youtube.com/watch?v=l1LfLASxSDM"
  },
  {
    "publishingDetails": {
      "no": 20,
      "roles": [
        {
          "role": "C",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Johnasa",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "DIA",
      "artist": [
        "Nobi",
        "Johnasa"
      ],
      "language": "Malay",
      "duration": "",
      "releaseDate": ""
    },
    "masterRecordingDetails": {
      "version": "",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Amirul Asrar bin Mazlan "
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 21,
      "roles": [
        {
          "role": "C",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "Kau Pergi V2",
      "artist": [
        "Nobi"
      ],
      "language": "Malay",
      "duration": "",
      "releaseDate": "9/29/24"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ3 24 10028",
      "upc": "",
      "repertoireOwner": "Amirul Asrar bin Mazlan "
    },
    "link": "https://www.youtube.com/watch?v=YhF_wU4D4Aw"
  },
  {
    "publishingDetails": {
      "no": 22,
      "roles": [
        {
          "role": "C",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Johnasa",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "Ngadu",
      "artist": [
        "Nobi"
      ],
      "language": "Malay",
      "duration": "",
      "releaseDate": "9/29/24"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ3 24 10029",
      "upc": "",
      "repertoireOwner": "Amirul Asrar bin Mazlan "
    },
    "link": "https://www.youtube.com/watch?v=U4zTN1T0b4Q"
  },
  {
    "publishingDetails": {
      "no": 23,
      "roles": [
        {
          "role": "C",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Johnasa",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "Bracelet Purple",
      "artist": [
        "Nobi",
        "Johnasa"
      ],
      "language": "Malay",
      "duration": "",
      "releaseDate": "9/29/24"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ3 24 10030",
      "upc": "",
      "repertoireOwner": "Amirul Asrar bin Mazlan "
    },
    "link": "https://www.youtube.com/watch?v=tstFx0fkUu4"
  },
  {
    "publishingDetails": {
      "no": 24,
      "roles": [
        {
          "role": "C",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Johnasa",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "Masa",
      "artist": [
        "Nobi",
        "Johnasa"
      ],
      "language": "Malay",
      "duration": "",
      "releaseDate": "9/29/24"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ3 24 10031",
      "upc": "",
      "repertoireOwner": "Amirul Asrar bin Mazlan "
    },
    "link": "https://www.youtube.com/watch?v=idmyzrgqRhM"
  },
  {
    "publishingDetails": {
      "no": 25,
      "roles": [
        {
          "role": "C",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "Cermin Mimpi",
      "artist": [
        "Nobi"
      ],
      "language": "Malay",
      "duration": "",
      "releaseDate": "9/29/24"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ3 24 10032",
      "upc": "",
      "repertoireOwner": "Amirul Asrar bin Mazlan "
    },
    "link": "https://www.youtube.com/watch?v=66btPW7luDo"
  },
  {
    "publishingDetails": {
      "no": 26,
      "roles": [
        {
          "role": "C",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Johnasa",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "Cumulonimbus",
      "artist": [
        "Nobi",
        "Johnasa"
      ],
      "language": "Malay",
      "duration": "",
      "releaseDate": "9/29/24"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ3 24 10033",
      "upc": "",
      "repertoireOwner": "Amirul Asrar bin Mazlan "
    },
    "link": "https://www.youtube.com/watch?v=OGwHk3vVXlA"
  },
  {
    "publishingDetails": {
      "no": 27,
      "roles": [
        {
          "role": "C",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Johnasa",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Amirul Asrar bin Mazlan",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "Tak Nak Tunggu",
      "artist": [
        "Nobi",
        "Johnasa"
      ],
      "language": "Malay",
      "duration": "",
      "releaseDate": "9/29/24"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ3 24 10034",
      "upc": "",
      "repertoireOwner": "Amirul Asrar bin Mazlan "
    },
    "link": "https://www.youtube.com/watch?v=E2xDDi1LIUY"
  },
    // Add other Nobi songs here
  ];