// gw-catalog.js
export const gwCatalog = [
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "25.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "CA",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "25.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
          ],
          "no": 1
      },
      "songDetails": {
          "title": "12 JAM",
          "artist": ["GARD WUZGUT"],
          "language": "Malay",
          "duration": "3'26\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "CA",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "25.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "25.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
          ],
          "no": 2
      },
      "songDetails": {
          "title": "250",
          "artist": ["GARD WUZGUT"],
          "language": "Malay",
          "duration": "2'8\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "C",
                  "songwriter": "AIMAN AMINUDDIN",
                  "percentage": "16.68%",
                  "publisher": "AIMAN AMINUDDIN"
              },
              {
                  "role": "CA",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "20.83%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "20.83%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "SE",
                 "percentage": "20.83%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "20.83%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              }
          ],
          "no": 3
      },
      "songDetails": {
          "title": "404",
          "artist": ["GARD WUZGUT"],
          "language": "Malay",
          "duration": "4'6\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "CA",
                  "songwriter": "JAYD HARITH",
                  "percentage": "30.00%",
                  "publisher": "JAYD HARITH"
              },
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "25.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "E",
                 "percentage": "20.00%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
          ],
          "no": 4
      },
      "songDetails": {
          "title": "APA DALAM BEG",
          "artist": ["GARD WUZGUT"],
          "language": "Malay",
          "duration": "4'23\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "CA",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "20.83%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "CA",
                  "songwriter": "LOQUE",
                  "percentage": "16.68%",
                  "publisher": "LOQUE"
              },
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "20.83%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "SE",
                 "percentage": "20.83%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "20.83%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              }
          ],
          "no": 5
      },
      "songDetails": {
          "title": "BETUL AKU SALAH",
          "artist": ["GARD WUZGUT"],
          "language": "Malay",
          "duration": "3'2\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "CA",
                  "songwriter": "IKHMAL DANIAL BIN HELME ISKANDAR",
                  "percentage": "30.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "CA",
                  "songwriter": "MUHAMMAD HAKIIM BIN TAHIR",
                  "percentage": "10.00%",
                  "publisher": "MUHAMMAD HAKIIM BIN TAHIR"
              },
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "10.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "SE",
                 "percentage": "30.00%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "10.00%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "10.00%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              }
          ],
          "no": 6
      },
      "songDetails": {
          "title": "FEEL LIKE KMAIN",
          "artist": ["ADYB/GARD WUZGUT/OFFGRID/QUAI"],
          "language": "Malay",
          "duration": "3'51\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "IKHMAL DANIAL BIN HELME ISKANDAR"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "C",
                  "songwriter": "BROKEN ENGLISH",
                  "percentage": "20.00%",
                  "publisher": "BROKEN ENGLISH"
              },
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "20.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "CA",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "20.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "SE",
                 "percentage": "20.00%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "20.00%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              }
          ],
          "no": 7
      },
      "songDetails": {
          "title": "GADIS BERNAMA ZETI",
          "artist": ["GARD WUZGUT"],
          "language": "Malay",
          "duration": "3'4\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "C",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "25.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "A",
                  "songwriter": "AIRLIFTZ",
                  "percentage": "15.00%",
                  "publisher": "AIRLIFTZ"
              },
              {
                  "role": "A",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "10.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "E",
                  "songwriter": "TONGTONG ASIA SDN BHD",
                  "percentage": "0.00%",
                  "publisher": "TONGTONG ASIA SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "10.00%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                  "songwriter": "WARNER CHAPPELL MUSIC (MALAYSIA) SDN BHD",
                  "percentage": "15.00%",
                  "publisher": "WARNER CHAPPELL MUSIC (MALAYSIA) SDN BHD"
              }
          ],
          "no": 8
      },
      "songDetails": {
          "title": "GHOST",
          "artist": ["AIRLIFTZ/GARD WUZGUT"],
          "language": "English",
          "duration": "5'0\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "WUZGUT"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "CA",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "25.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "25.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
          ],
          "no": 9
      },
      "songDetails": {
          "title": "HARITU BOLEH JE",
          "artist": ["GARD WUZGUT"],
          "language": "Malay",
          "duration": "4'0\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "25.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "CA",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "25.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
          ],
          "no": 10
      },
      "songDetails": {
          "title": "HUH",
          "artist": ["GARD WUZGUT"],
          "language": "Malay",
          "duration": "3'5\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "CA",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "22.50%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "CA",
                  "songwriter": "RAEIS FIRDAUS",
                  "percentage": "10.00%",
                  "publisher": "RAEIS FIRDAUS"
              },
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "22.50%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "SE",
                 "percentage": "22.50%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "22.50%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              }
          ],
          "no": 11
      },
      "songDetails": {
          "title": "KUALA LUMPUR BANJIR YE MALAM NI",
          "artist": ["GARD WUZGUT"],
          "language": "Malay",
          "duration": "4'0\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "CA",
                  "songwriter": "EZZAD IMAN BIN JOHAN",
                  "percentage": "25.00%",
                  "publisher": "EZZAD IMAN BIN JOHAN"
              },
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "25.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
          ],
          "no": 12
      },
      "songDetails": {
          "title": "LAJU MANA",
          "artist": ["GARD WUZGUT"],
          "language": "Malay",
          "duration": "2'54\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "CA",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "16.66%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "CA",
                  "songwriter": "PG KHAIRUL",
                  "percentage": "33.32%",
                  "publisher": "PG KHAIRUL"
              },
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "16.68%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "SE",
                 "percentage": "16.68%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "16.66%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              }
          ],
          "no": 13
      },
      "songDetails": {
          "title": "LEBIHDEKAT",
          "artist": ["GARD WUZGUT"],
          "language": "Malay",
          "duration": "2'33\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "CA",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "16.66%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "CA",
                  "songwriter": "NORMALIS NORDIN",
                  "percentage": "33.32%",
                  "publisher": "NORMALIS NORDIN"
              },
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "16.68%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "SE",
                 "percentage": "16.68%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "16.66%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              }
          ],
          "no": 14
      },
      "songDetails": {
          "title": "MALIS SEJUK",
          "artist": ["GARD WUZGUT"],
          "language": "Malay",
          "duration": "4'0\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "CA",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "33.34%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "A",
                  "songwriter": "IKHMAL DANIAL BIN HELME ISKANDAR",
                  "percentage": "8.33%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "A",
                  "songwriter": "MEGAT MOHAMAD SHAZRUL BIN MOHD REJAB",
                  "percentage": "8.33%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "SE",
                 "percentage": "8.33%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "33.34%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "8.33%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              }
          ],
          "no": 15
      },
      "songDetails": {
          "title": "MESIN MASA",
          "artist": ["GARD WUZGUT"],
          "language": "Malay",
          "duration": "3'0\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "CA",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "25.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "25.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
          ],
          "no": 16
      },
      "songDetails": {
          "title": "MOONRIDERZ",
          "artist": ["GARD WUZGUT"],
          "language": "Malay",
          "duration": "2'31\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "CA",
                  "songwriter": "ARIEF OTHMAN",
                  "percentage": "27.50%",
                  "publisher": "ARIEF OTHMAN"
              },
              {
                  "role": "CA",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "13.75%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "CA",
                  "songwriter": "KHODI",
                  "percentage": "8.75%",
                  "publisher": "KHODI"
              },
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "13.75%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "E",
                  "songwriter": "SONY MUSIC PUBLISHING SDN BHD",
                  "percentage": "8.75%",
                  "publisher": "SONY MUSIC PUBLISHING SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "13.75%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "13.75%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              }
          ],
          "no": 17
      },
      "songDetails": {
          "title": "ONEUTAMA",
          "artist": ["GARD WUZGUT"],
          "language": "Malay",
          "duration": "2'53\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "CA",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "25.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "25.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
          ],
          "no": 18
      },
      "songDetails": {
          "title": "OUTRO",
          "artist": ["GARD WUZGUT"],
          "language": "English",
          "duration": "1'32\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "CA",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "15.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "35.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "SE",
                 "percentage": "15.00%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "35.00%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              }
          ],
          "no": 19
      },
      "songDetails": {
          "title": "PAPERBOI",
          "artist": ["GARD WUZGUT"],
          "language": "Malay",
          "duration": "2'15\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "C",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "25.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "A",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "12.50%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "A",
                  "songwriter": "OFFGRID",
                  "percentage": "12.50%",
                  "publisher": "OFFGRID"
              },
              {
                  "role": "SE",
                 "percentage": "12.50%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              }
          ],
          "no": 20
      },
      "songDetails": {
          "title": "RAHMAT BULAN",
          "artist": ["GARD/GARD WUZGUT/OFFGRID/WUZGUT"],
          "language": "Malay",
          "duration": "4'0\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "WUZGUT"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "CA",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "16.67%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "CA",
                  "songwriter": "MUHAMMAD HAKIIM BIN TAHIR",
                  "percentage": "16.67%",
                  "publisher": "MUHAMMAD HAKIIM BIN TAHIR"
              },
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "16.67%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "SE",
                 "percentage": "16.66%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "16.66%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "16.67%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              }
          ],
          "no": 21
      },
      "songDetails": {
          "title": "SAME",
          "artist": ["GARD WUZGUT"],
          "language": "Malay",
          "duration": "3'13\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "CA",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "25.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "25.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
          ],
          "no": 22
      },
      "songDetails": {
          "title": "SATU TAB",
          "artist": ["GARD WUZGUT"],
          "language": "Malay",
          "duration": "3'0\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "C",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "25.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "A",
                  "songwriter": "AMEER AZHAR",
                  "percentage": "12.50%",
                  "publisher": "AMEER AZHAR"
              },
              {
                  "role": "A",
                  "songwriter": "MEGAT MOHAMAD SHAZRUL BIN MOHD REJAB",
                  "percentage": "12.50%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "SE",
                 "percentage": "12.50%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              }
          ],
          "no": 23
      },
      "songDetails": {
          "title": "SENJA",
          "artist": ["ATTUNE/GARD WUZGUT"],
          "language": "Malay",
          "duration": "3'0\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "WUZGUT"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "CA",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "25.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "25.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
          ],
          "no": 24
      },
      "songDetails": {
          "title": "SHELDON OTW",
          "artist": ["GARD WUZGUT"],
          "language": "English",
          "duration": "1'50\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "CA",
                  "songwriter": "IKHMAL DANIAL BIN HELME ISKANDAR",
                  "percentage": "30.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "CA",
                  "songwriter": "MUHAMMAD HAKIIM BIN TAHIR",
                  "percentage": "10.00%",
                  "publisher": "MUHAMMAD HAKIIM BIN TAHIR"
              },
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "10.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "SE",
                 "percentage": "30.00%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "10.00%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "10.00%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              }
          ],
          "no": 25
      },
      "songDetails": {
          "title": "SKYWALKER",
          "artist": ["GARD WUZGUT/OFFGRID/QUAI"],
          "language": "Malay",
          "duration": "3'15\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "IKHMAL DANIAL BIN HELME ISKANDAR"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "C",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "22.50%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "A",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "20.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "A",
                  "songwriter": "OFFGRID",
                  "percentage": "7.50%",
                  "publisher": "OFFGRID"
              },
              {
                  "role": "SE",
                 "percentage": "7.50%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "22.50%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "20.00%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              }
          ],
          "no": 26
      },
      "songDetails": {
          "title": "SORRY IF I RINDU",
          "artist": ["GARD WUZGUT"],
          "language": "Malay",
          "duration": "3'0\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "C",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "25.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "A",
                  "songwriter": "MEGAT MOHAMAD SHAZRUL BIN MOHD REJAB",
                  "percentage": "12.50%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "A",
                  "songwriter": "OFFGRID",
                  "percentage": "12.50%",
                  "publisher": "OFFGRID"
              },
              {
                  "role": "SE",
                 "percentage": "12.50%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              }
          ],
          "no": 27
      },
      "songDetails": {
          "title": "SORRY IF RINDU",
          "artist": ["GARD/GARD WUZGUT/OFFGRID/WUZGUT"],
          "language": "Malay",
          "duration": "3'0\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "WUZGUT"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "CA",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "16.66%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "CA",
                  "songwriter": "IZZ4D",
                  "percentage": "20.00%",
                  "publisher": "IZZ4D"
              },
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "16.67%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "E",
                 "percentage": "13.34%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "16.67%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "16.66%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              }
          ],
          "no": 28
      },
      "songDetails": {
          "title": "SPD",
          "artist": ["GARD WUZGUT"],
          "language": "Malay",
          "duration": "2'53\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "CA",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "16.67%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "CA",
                  "songwriter": "KHAIRIL AZMIN BIN KAMARUL BAHRIN",
                  "percentage": "20.00%",
                  "publisher": "KHAIRIL AZMIN BIN KAMARUL BAHRIN"
              },
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "16.67%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "E",
                 "percentage": "13.33%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "16.67%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "16.66%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              }
          ],
          "no": 29
      },
      "songDetails": {
          "title": "SPECIAL K",
          "artist": ["GARD WUZGUT"],
          "language": "Malay",
          "duration": "3'3\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "CA",
                  "songwriter": "IKHMAL DANIAL BIN HELME ISKANDAR",
                  "percentage": "30.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "12.50%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "CA",
                  "songwriter": "SXPH",
                  "percentage": "7.50%",
                  "publisher": "SXPH"
              },
              {
                  "role": "E",
                  "songwriter": "FAITHFUL MUSIC SDN BHD",
                  "percentage": "0.00%",
                  "publisher": "FAITHFUL MUSIC SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "12.50%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "30.00%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                  "songwriter": "WARNER CHAPPELL MUSIC (MALAYSIA) SDN BHD",
                  "percentage": "7.50%",
                  "publisher": "WARNER CHAPPELL MUSIC (MALAYSIA) SDN BHD"
              }
          ],
          "no": 30
      },
      "songDetails": {
          "title": "SWIPE UP",
          "artist": ["GARD WUZGUT/QUAI/SXPH"],
          "language": "Malay",
          "duration": "3'2\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "IKHMAL DANIAL BIN HELME ISKANDAR"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "C",
                  "songwriter": "MUHAMMAD HAKIIM BIN TAHIR",
                  "percentage": "8.33%",
                  "publisher": "MUHAMMAD HAKIIM BIN TAHIR"
              },
              {
                  "role": "C",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "8.33%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "CA",
                  "songwriter": "IKHMAL DANIAL BIN HELME ISKANDAR",
                  "percentage": "33.34%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "SE",
                 "percentage": "33.34%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "8.33%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "8.33%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              }
          ],
          "no": 31
      },
      "songDetails": {
          "title": "TANYA TUHAN",
          "artist": ["ADYB/GARD WUZGUT/OFFGRID/QUAI"],
          "language": "Malay",
          "duration": "4'25\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "CA",
                  "songwriter": "MUHAMMAD HAKIIM BIN TAHIR",
                  "percentage": "20.00%",
                  "publisher": "MUHAMMAD HAKIIM BIN TAHIR"
              },
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "30.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "SE",
                 "percentage": "30.00%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "20.00%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              }
          ],
          "no": 32
      },
      "songDetails": {
          "title": "TANYE",
          "artist": ["AP OFFGRID/GARD/GARD WUZGUT"],
          "language": "Malay",
          "duration": "2'55\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "CA",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "16.67%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "CA",
                  "songwriter": "NAIM FAUZI",
                  "percentage": "33.34%",
                  "publisher": "NAIM FAUZI"
              },
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "16.67%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "SE",
                 "percentage": "16.66%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "16.66%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              }
          ],
          "no": 33
      },
      "songDetails": {
          "title": "TENGOK NEGERI LUAR",
          "artist": ["GARD WUZGUT"],
          "language": "Malay",
          "duration": "4'14\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "C",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "12.50%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "C",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "12.50%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "A",
                  "songwriter": "NORMALIS NORDIN",
                  "percentage": "50.00%",
                  "publisher": "NORMALIS NORDIN"
              },
              {
                  "role": "SE",
                 "percentage": "12.50%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              }
          ],
          "no": 34
      },
      "songDetails": {
          "title": "TRIP MALIS",
          "artist": ["GARD WUZGUT"],
          "language": "English",
          "duration": "3'0\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "CA",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "25.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "25.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
          ],
          "no": 35
      },
      "songDetails": {
          "title": "WAH",
          "artist": ["GARD WUZGUT"],
          "language": "Malay",
          "duration": "3'0\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "C",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "25.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "A",
                  "songwriter": "MEGAT MOHAMAD SHAZRUL BIN MOHD REJAB",
                  "percentage": "25.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
          ],
          "no": 36
      },
      "songDetails": {
          "title": "WONDERLAND",
          "artist": ["GARD WUZGUT"],
          "language": "Malay",
          "duration": "3'0\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "C",
                  "songwriter": "I-SKY",
                  "percentage": "30.00%",
                  "publisher": "I-SKY"
              },
              {
                  "role": "A",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "25.00%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "E",
                 "percentage": "20.00%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
          ],
          "no": 37
      },
      "songDetails": {
          "title": "WONDERLAND (I SKY REMIX)",
          "artist": ["GARD WUZGUT/I SKY"],
          "language": "English",
          "duration": "5'0\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  },
  {
      "publishingDetails": {
          "roles": [
              {
                  "role": "CA",
                  "songwriter": "BAQHTEAR RASHID",
                  "percentage": "17.50%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "CA",
                  "songwriter": "HILMI NORLAN",
                  "percentage": "20.00%",
                  "publisher": "HILMI NORLAN"
              },
              {
                  "role": "CA",
                  "songwriter": "SHAZRUL REJAB",
                  "percentage": "22.50%",
                  "publisher": "0108 SLATAN SOLUTION"
              },
              {
                  "role": "SE",
                 "percentage": "22.50%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              },
              {
                  "role": "SE",
                 "percentage": "17.50%",
                  "publisher": "TAJA ARCHIVE SDN BHD"
              }
          ],
          "no": 38
      },
      "songDetails": {
          "title": "ZETI AZIZ",
          "artist": ["GARD WUZGUT"],
          "language": "Malay",
          "duration": "3'52\"",
          "releaseDate": "N/A"
      },
      "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "N/A",
          "upc": "N/A",
          "repertoireOwner": "BAQHTEAR RASHID/SHAZRUL REJAB"
      },
      "link": ""
  }
];

// Replace "Malay/Eng" with "Malay/English"
const updatedCatalog = gwCatalog.map(item => {
  if (item.songDetails.language === "Malay/Eng") {
    item.songDetails.language = "Malay/English";
  }
  return item;
});