// jaystation-catalog.js
export const jaystationCatalog = [
  {
    "publishingDetails": {
      "no": 1,
      "roles": [
        {
          "role": "C",
          "songwriter": "Naim bin Mohamad Fauzi",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Naim bin Mohamad Fauzi",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "JCKTA",
      "artist": [
        "JAYSTATION"
      ],
      "language": "Malay",
      "duration": "3:48",
      "releaseDate": "19/6/2020"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6132044381",
      "upc": "",
      "repertoireOwner": "Naim Bin Muhamad Fauzi\n"
    },
    "link": "https://www.youtube.com/watch?v=5zHA7rQYDfM"
  },
  {
    "publishingDetails": {
      "no": 2,
      "roles": [
        {
          "role": "C",
          "songwriter": "Naim bin Mohamad Fauzi",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Naim bin Mohamad Fauzi",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "GOOD NOW",
      "artist": [
        "JAYSTATION"
      ],
      "language": "English",
      "duration": "4:09",
      "releaseDate": "18/11/2020"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6QE2023813",
      "upc": "",
      "repertoireOwner": "Naim Bin Muhamad Fauzi\n"
    },
    "link": "https://www.youtube.com/watch?v=SDKQCp_VlCs"
  },
  {
    "publishingDetails": {
      "no": 3,
      "roles": [
        {
          "role": "C",
          "songwriter": "Naim bin Mohamad Fauzi",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Naim bin Mohamad Fauzi",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "PANCAINDERA",
      "artist": [
        "JAYSTATION"
      ],
      "language": "Malay",
      "duration": "4:29",
      "releaseDate": "18/11/2020"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6QE2023815",
      "upc": "",
      "repertoireOwner": "Naim Bin Muhamad Fauzi\n"
    },
    "link": "https://www.youtube.com/watch?v=iDrC7cMejQE"
  },
  {
    "publishingDetails": {
      "no": 4,
      "roles": [
        {
          "role": "C",
          "songwriter": "Naim bin Mohamad Fauzi",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Naim bin Mohamad Fauzi",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "KAMBUH",
      "artist": [
        "JAYSTATION"
      ],
      "language": "Malay",
      "duration": "3:53",
      "releaseDate": "18/11/2020"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6QE2023816",
      "upc": "",
      "repertoireOwner": "Naim Bin Muhamad Fauzi\n"
    },
    "link": "https://www.youtube.com/watch?v=mxFe1xYsGgU"
  },
  {
    "publishingDetails": {
      "no": 5,
      "roles": [
        {
          "role": "C",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "50.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "A",
          "songwriter": "Naim bin Mohamad Fauzi",
          "percentage": "12.5",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Iqra' Aizzuddin",
          "percentage": "12.5",
          "publisher": ""
        },
        {
          "role": "A",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "12.5",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "A",
          "songwriter": "Nadir Khan",
          "percentage": "12.5",
          "publisher": ""
        }
      ]
    },
    "songDetails": {
      "title": "RENAISSANCE",
      "artist": [
        "JAYSTATION"
      ],
      "language": "Malay/English",
      "duration": "2:31",
      "releaseDate": "23/5/2021"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6132126273",
      "upc": "",
      "repertoireOwner": "Naim Bin Muhamad Fauzi\n"
    },
    "link": "https://www.youtube.com/watch?v=c0e_CwM_vak"
  },
  {
    "publishingDetails": {
      "no": 6,
      "roles": [
        {
          "role": "C",
          "songwriter": "Naim bin Mohamad Fauzi",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Naim bin Mohamad Fauzi",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "HEARTBREAKHOTEL2020",
      "artist": [
        "JAYSTATION"
      ],
      "language": "Malay",
      "duration": "2:45",
      "releaseDate": "20/7/2021"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6SA2179613",
      "upc": "",
      "repertoireOwner": "Naim Bin Muhamad Fauzi\n"
    },
    "link": "https://www.youtube.com/watch?v=Dk015RlgLbk"
  },
  {
    "publishingDetails": {
      "no": 7,
      "roles": [
        {
          "role": "C",
          "songwriter": "Naim bin Mohamad Fauzi",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Naim bin Mohamad Fauzi",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "HEARTBREAKHOTEL2020 (Crusher A Remix)",
      "artist": [
        "JAYSTATION"
      ],
      "language": "Malay",
      "duration": "3:45",
      "releaseDate": "20/7/2021"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6SA2179614",
      "upc": "",
      "repertoireOwner": "Naim Bin Muhamad Fauzi\n"
    },
    "link": "https://www.youtube.com/watch?v=P-J1kpZAi7U"
  },
  {
    "publishingDetails": {
      "no": 8,
      "roles": [
        {
          "role": "C",
          "songwriter": "Naim bin Mohamad Fauzi",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Muhammad Arif Putera",
          "percentage": "25.00%",
          "publisher": ""
        },
        {
          "role": "A",
          "songwriter": "Naim bin Mohamad Fauzi",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "HEARTBREAKHOTEL2020 (x-Decade & pyaniX Remix)",
      "artist": [
        "JAYSTATION"
      ],
      "language": "Malay",
      "duration": "1:36",
      "releaseDate": "20/7/2021"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6SA2179615",
      "upc": "",
      "repertoireOwner": "Naim Bin Muhamad Fauzi\n"
    },
    "link": "https://www.youtube.com/watch?v=lJMOB325_wA"
  },
  {
    "publishingDetails": {
      "no": 9,
      "roles": [
        {
          "role": "C",
          "songwriter": "Naim bin Mohamad Fauzi",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Naim bin Mohamad Fauzi",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "GROCERY RUN",
      "artist": [
        "JAYSTATION"
      ],
      "language": "English",
      "duration": "2:39",
      "releaseDate": "12/6/23"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6QE2369771",
      "upc": "",
      "repertoireOwner": "Naim Bin Muhamad Fauzi\n"
    },
    "link": "https://www.youtube.com/watch?v=kZeGwvULP1o"
  },
  {
    "publishingDetails": {
      "no": 10,
      "roles": [
        {
          "role": "C",
          "songwriter": "Naim bin Mohamad Fauzi",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Naim bin Mohamad Fauzi",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "PRAY FOR ME",
      "artist": [
        "JAYSTATION"
      ],
      "language": "English",
      "duration": "3:14",
      "releaseDate": "12/6/23"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6QE2369772",
      "upc": "",
      "repertoireOwner": "Naim Bin Muhamad Fauzi\n"
    },
    "link": "https://www.youtube.com/watch?v=ltse86bGbVw"
  },
  {
    "publishingDetails": {
      "no": 11,
      "roles": [
        {
          "role": "C",
          "songwriter": "Naim bin Mohamad Fauzi",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Naim bin Mohamad Fauzi",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "KURUNG",
      "artist": [
        "JAYSTATION"
      ],
      "language": "Malay",
      "duration": "1:50",
      "releaseDate": "12/6/23"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6QE2369773",
      "upc": "",
      "repertoireOwner": "Naim Bin Muhamad Fauzi\n"
    },
    "link": "https://www.youtube.com/watch?v=OOwUAOnwDAw"
  },
  {
    "publishingDetails": {
      "no": 12,
      "roles": [
        {
          "role": "C",
          "songwriter": "Naim bin Mohamad Fauzi",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Naim bin Mohamad Fauzi",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "VICTORY LAP",
      "artist": [
        "JAYSTATION"
      ],
      "language": "English",
      "duration": "4:16",
      "releaseDate": "12/6/23"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6QE2369774",
      "upc": "",
      "repertoireOwner": "Naim Bin Muhamad Fauzi\n"
    },
    "link": "https://www.youtube.com/watch?v=dqkcv4pLoP8"
  },
  {
    "publishingDetails": {
      "no": 13,
      "roles": [
        {
          "role": "C",
          "songwriter": "Naim bin Mohamad Fauzi",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Naim bin Mohamad Fauzi",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "PLUG",
      "artist": [
        "JAYSTATION"
      ],
      "language": "English",
      "duration": "2:48",
      "releaseDate": "12/6/23"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6QE2369775",
      "upc": "",
      "repertoireOwner": "Naim Bin Muhamad Fauzi\n"
    },
    "link": "https://www.youtube.com/watch?v=eGz0ECXA7Po"
  },
    // Add more songs for JAYSTATION
  ];